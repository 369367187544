import React, { useEffect } from "react"
import SEO from "../../components/Seo/Seo"

import { navigate } from "gatsby"

const aboutMetadata = {
  Title: "Over ons - Flipbase",
  Keywords: ["Flipbase over ons", "over Flipbase"],
  Description:
    "Wij zijn een video technologiebedrijf, met geïntegreerde asynchrone videocommunicatie voor recruitment professionals.",
  Image: "/seo-image-general.png",
}

export default () => {
  useEffect(() => {
    navigate("/AboutUs")
  }, [])

  return (
    <SEO
      title={aboutMetadata.Title}
      description={aboutMetadata.Description}
      image={aboutMetadata.Image}
      keywords={aboutMetadata.Keywords}
    />
  )
}
